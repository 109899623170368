/* Global dark theme adjustments */
.ant-btn-primary {
  border-color: #1890ff; /* Adjusted for better visibility in dark mode */
}

/* Layout */
.login-form-container {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Positions the form towards the top */
  padding-top: 10vh; /* Adds space from the top. Adjust as needed. */
  height: 100vh;
}

.login-form {
  width: 600px; /* Adjust width as per your needs */
  padding: 40px; /* Increased padding for a larger form */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1); /* Adjusted for dark mode */
  border-radius: 5px;
  background: #141414; /* Dark background for the form */
  color: #fff; /* Light text for readability */
}

/* Buttons */
.login-form-button {
  width: 100%;
}

/* Container */
.login-container {
  width: 300px; /* Adjust width as needed */
  margin: auto;
  text-align: center;
}

/* Header */
.app-header h1 {
  color: black; /* Light text for readability */
}

.sso-enabled {
  color: black; /* Light text for readability */
  margin: 10px 0; /* Added space around the SSO message */
}

/* Login Methods */
.login-methods .ant-btn {
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
}

/* Divider - You may not need to style this if Ant Design's default is suitable */
.divider {
  color: grey; /* Light text for readability */
}

/* Adjustments for Ant Design components in dark mode */
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #1890ff; /* Adjusted for better visibility in dark mode */
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border-color: #1890ff; /* Adjusted for better visibility in dark mode */
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); /* Adjusted for dark mode */
}

/* You may need to add more styles for icons if they are not visible properly */

/* Ensure that you are wrapping your application with <ConfigProvider theme="dark"> to enable Ant Design's dark theme */
