/* FacilityImagesPage.css */

.facility-section {
  margin-bottom: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.facility-address {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.image-grid {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.image-area {
  flex: 1 1 100%;
  max-width: 100%;
  text-align: center;
}
.center-switch {
    text-align: center;
    flex-direction: row;
    justify-self: center;
}
.centered-form-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 0;
    margin: 0;
    /* margin-bottom: 20px; */
  }
.facility-image {
    height: 100px;
    width: auto;
    cursor: pointer;
    border-radius: 8px;
    object-fit: cover;
    transition: transform 0.2s ease;
  }

.facility-image:hover {
  transform: scale(1.05);
}

.category-title {
  margin-top: 5px;
  font-size: 16px;
  font-weight: bold;
}