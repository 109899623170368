/* Spinner.css */
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .custom-spinner-image {
    position: absolute;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite; /* Adjust the duration and timing as needed */
  }