/* For desktop and up (default) */
.small-font-table .ant-table-tbody > tr > td {
  font-size: 14px; /* Adjust as needed */
}

.small-font-table .ant-table-thead > tr > th {
  font-size: 14px; /* Adjust as needed for table headers */
}

/* For mobile screens (e.g., max-width 576px) */
@media (max-width: 576px) {
  .small-font-table .ant-table-tbody > tr > td {
    font-size: 10px; /* Adjust as needed */
  }

  .small-font-table .ant-table-thead > tr > th {
    font-size: 10px; /* Adjust as needed for table headers */
  }
}
