html,
body {
  margin: 0;
}

.EngrainApp {
  display: grid;
  font-family: sans-serif;
  /* grid-template-columns: 150px calc(100vw - 150px); */
  grid-template-rows: 60px calc(100vh - 60px);
  grid-template-areas:
    "floor-picker floor-picker"
    "unit-list main";
}

.Main {
  grid-area: main;
  width: 100%;
  height: 100%;
}

.FloorPicker {
  grid-area: floor-picker;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}

.FloorPicker button {
  margin: 8px;
  flex-grow: 1;
}

.FloorPicker button.selected,
.FloorPicker button:hover {
  background-color: #c2c2c2;
}

.UnitList {
  grid-area: unit-list;
  margin: 0;
  border-right: 1px solid #ccc;
}

.UnitList .empty {
  color: #ccc;
  margin: 13px 3px;
  font-size: 12px;
  text-align: center;
}

.UnitList .unit {
  display: flex;
  text-align: center;
  justify-content: space-between;
  padding: 3px;
  margin: 3px 8px;
  border: 1px dotted #ccc;
}

.UnitList .unit:first-child {
  margin-top: 8px;
}

.UnitPopOver {
  position: absolute;
  user-select: none;
  left: 10px;
  padding: 3px;
  color:#000;
  pointer-events: none;
  background: #fff;
  border: 1px solid #000;
  box-shadow: 1px 1px 1px #999;
}

.UnitPopOver .title {
  font-size: 12px;
  margin: 0;
}