
.store-settings-form {
  background-color: #ffffff; /* Your desired background color */
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1); /* Adjust shadow to match your design */
}

.store-settings-form .ant-typography {
  color: #333; /* Adjust text color as needed */
}

.store-name {
  font-size: 16px; /* Adjust size as needed */
  color: #555; /* Adjust text color as needed */
  margin-bottom: 24px; /* Adjust spacing as needed */
}

.store-settings-form .ant-form-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px; /* Adjust spacing as needed */
}

.store-settings-form .ant-form-item-label {
  padding: 0; /* Remove padding if necessary */
}

.store-settings-form .ant-form-item-control {
  flex: 1; /* Ensure control takes up available space */
}