/* Mobile First Default */
.siderStyle {
    width: 20%;
    text-align: 'center';
    line-height: '120px';
    color: '#fff';

}
.layoutStyle {
    width: 100%;
    text-align: 'center';
    line-height: '120px';
    color: '#fff';

}
/* for edit basic facility details component */
.site-form-in-drawer-wrapper {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100%;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
    border-top: 1px solid #e9e9e9;
  }


/* Tablet */
@media (min-width: 600px) {
    .siderStyle {
        width: 250px;
    }
}

/* Desktop */
@media (min-width: 1024px) {
    .siderStyle {
        width: 400px;
    }
}
