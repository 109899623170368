/* UploadFacilityImagesComponent.css */
.upload-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns */
    grid-template-rows: auto auto; /* Two rows */
    gap: 16px;
    margin: 16px;
  }
  
  .upload-area {
    text-align: center;
  }
  
  .category-title {
    margin-top: 8px;
    font-weight: bold;
  }
  
  .upload-text {
    margin-top: 8px;
  }
  
  /* Add more styles as needed */
  